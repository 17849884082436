/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "En mi cruzada contra las patentes, sobretodo las de software y hardware, contra los monopolios y a favor de una cultura del autoproducción, he decidido empezar esta iniciativa, Ideas Libres."), "\n", React.createElement(_components.p, null, "A lo largo del día a mucha gente se le ocurren ideas geniales, ideas que pueden ser mas o menos practicas, mas o menos asequibles, revolucionarias o no, en resumen todo tipo de ideas, pero la mayor parte de las veces esas ideas se quedan en el tintero. Mi objetivo es liberar todas esas ideas, ideas que probablemente nunca se pondrían en funcionamiento por falta de recursos de la persona que la ideó pueden pasar a ser parte del dominio común y si alguien quiere y puede llevarla a cabo lo podrá hacer sin ningún tipo de restricción. De esta forma limitamos el avance de las patentes y nos deshacemos del mercadeo de ideas facilitando además su reciclaje."), "\n", React.createElement(_components.p, null, "A partir de ahora escribiré artículos con Ideas Libres y estos irán marcados por un [IL] al principio del título."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
